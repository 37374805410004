<template>
  <teleport to="head">
    <title>{{ titleData }}</title>
    <meta name="description" :content="descriptionData">
  </teleport>
</template>

<script>
import { baseUrl } from "../../modules/utilities";

export default {
  props: {
    titleProp: String,
    descriptionProp: String,
    pageType: String
  },
  data() {
    return {
      title: "",
      description: ""
    };
  },
  computed: {
    titleData() {
      return this.title || this.titleProp;
    },
    descriptionData() {
      return this.description || this.descriptionProp;
    }
  },
  async created() {
    if (this.pageType) await this.loadMeta();

    // Ensure only one meta description exists
    this.replaceMetaDescription();
  },
  methods: {
    async loadMeta() {
      const url = `${baseUrl}api/v1/get_meta?page_type=${this.pageType}`;
      console.log("Fetching meta from:", url); // Debug log
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      const responseData = await response.json();
      console.log("Meta response status:", response.status); // Debug status
      if (!response.ok) {
        console.error("Meta fetch error:", response.status, responseData); // Log errors
        return;
      }
      if (responseData?.meta?.title) this.title = responseData.meta.title;
      if (responseData?.meta?.description) this.description = responseData.meta.description;
    },

    replaceMetaDescription() {
      const existingMeta = document.querySelectorAll('meta[name="description"]');
      if (existingMeta.length > 1) {
        // Remove all except the last one
        for (let i = 0; i < existingMeta.length - 1; i++) {
          existingMeta[i].remove();
        }
      }
    }
  }
};
</script>
