import { fetchData } from "@/modules/utilities";
import { baseUrl } from "../../../modules/utilities";

export default {
  async articlesDetails(context, payload) {
    let url = `${baseUrl}api/v1/articles/` + payload;
    console.log("Fetching article from:", url); // Debug log
    let responseData = [];

    await fetchData(url, null, context.getters.token)
      .then((res) => responseData = res)
      .catch((err) => {
        console.error("Article fetch error:", err); // Better error logging
        throw err; // Propagate error to caller
      });

    context.commit('setArticlesDetails', responseData);
  },
};
